import React from 'react'

import PropTypes from "prop-types";

import { withTrans } from '../i18n/withTrans'

import Header from "./header";
import Footer from "./footer";

function Layout({ children }) {
    return (
        <div className="flex flex-col justify-between">

            <Header />

            <main className="py-6 px-4 sm:px-6 lg:px-8 flex-grow min-h-screen bg-gray-100">
                {children}
            </main>


            <Footer />

        </div>
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withTrans(Layout);
