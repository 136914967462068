import Cookies from 'js-cookie'
import jwt_decode from "jwt-decode";

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () => {
    if (isBrowser() && Cookies.get("_CA")) {
        let rawUser =  JSON.parse(Cookies.get("_CA"))
        let userJWT = jwt_decode(rawUser.jwt)

        if (userJWT != undefined) {
            if (assertAlive(userJWT)) {
                return rawUser.user
            }
        }
    }
    return {}
}

export const getUserTenant = () => {
    if (isBrowser() && Cookies.get("_CA")) {
        let rawUser =  JSON.parse(Cookies.get("_CA"))
        let userJWT = jwt_decode(rawUser.jwt)

        if (userJWT != undefined) {
            if (assertAlive(userJWT)) {
                return rawUser.user.Tenant
            }
        }
    }
    return {}
}

export const getJWT = () => {
    if (isBrowser() && Cookies.get("_CA")) {
        let rawUser =  JSON.parse(Cookies.get("_CA"))
        if (rawUser.jwt != undefined) {
            return rawUser.jwt
        }
    }
    return ""
}

export const isLoggedIn = () => {
    let jwt = getJWT()
    if (jwt != "" ) {
        let userJWT = jwt_decode(jwt)
        return !!userJWT.exp
    } else {
        return false
    }

}

export const logout = callback => {
    Cookies.remove('_CA')
    callback()
}

function assertAlive (decoded) {

    const now = Date.now().valueOf() / 1000

    if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
        console.log(`token expired: ${JSON.stringify(decoded)}`)
        return false
    }
    if (typeof decoded.nbf !== 'undefined' && decoded.nbf > now) {
        console.log(`token not yet valid: ${JSON.stringify(decoded)}`)
        return false
    }

    return true
}
