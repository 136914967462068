import "./src/styles/global.css";

import {ApolloProvider} from "@apollo/client";
import React from "react";
import {InMemoryCache} from "apollo-cache-inmemory";
import {ApolloClient} from "apollo-client";
import {createUploadLink} from "apollo-upload-client/public";
import Layout from "./src/components/layout";
import {getJWT, isLoggedIn} from "./src/services/auth";
import Login from "./src/components/login";
import { setContext } from '@apollo/client/link/context';

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    if (isLoggedIn()) {
        const token = getJWT();
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    }
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(createUploadLink({
            uri: process.env.GATSBY_STAPI
        })),
});

export const wrapPageElement = ({ element, props }) => {

    if (!isLoggedIn()) {
        switch (props.location.pathname) {
            case '/register':
                return <div {...props}>{element}</div>
            case '/forgot':
                console.log('ToDo');
                break;
            case (props.location.pathname.match('/business/public') ? props.location.pathname : ''):
                return element
            case (props.location.pathname.match('/business/setup') ? props.location.pathname : ''):
                return element
            default:
                return <Login />
        }
    } else {
        switch (props.location.pathname) {
            case '/tenant/new':
                return element
            case '/meet/setup':
                return element
            case '/meet/services':
                return element
            case '/meet/members':
                return element
            case '/meet/widget':
                return element
            case (props.location.pathname.match('/business/setup') ? props.location.pathname : ''):
                return element
            case (props.location.pathname.match('/business/public') ? props.location.pathname : ''):
                return element
            default:
                return <Layout>{element}</Layout>
        }
    }
}

export const wrapRootElement = ({ element, props }) => {
    return <ApolloProvider client={client} {...props}>{element}</ApolloProvider>
}
