import React, {Fragment, useEffect} from "react";
import {Menu, Popover, Transition} from "@headlessui/react";
import {MenuIcon, XIcon} from "@heroicons/react/outline";
import {getUserTenant, logout} from "../services/auth";
import {Link, navigate} from "gatsby";
import {useLazyQuery} from "@apollo/client";
import {ME_QUERY} from "../graphql/meGraphQL";
import logo from "../images/logo.png";

let navigation = []

function getNavigation () {

  let nav = [
    { name: 'Dashboard', href: '/' }
  ]

  let modules = getUserTenant().Module

  if (modules != undefined) {
    modules.map((module) => {
      switch (module.__typename) {
        case "ComponentModulClickAndMeet":
          nav.push({ name: 'Meet', href: '/meet/dashboard' })
          break;
        case "ComponentModulPage":
          nav.push({ name: 'Page', href: '/page/dashboard' })
          break;
        case "ComponentModulClickAndFood":
          nav.push({ name: 'Food', href: '/food/dashboard' })
          break;
        case "ComponentModulClickAndEvent":
          nav.push({ name: 'Event', href: '/event/dashboard' })
          break;
        case "ComponentModulStatistics":
          nav.push({ name: 'Statistics', href: '/statistics/dashboard' })
          break;
        case "ComponentModulBusinessCard":
          nav.push({ name: 'Business Card', href: '/business/dashboard' })
          break;
        case "ComponentModulClickAndCollect":
          nav.push({ name: 'Collect', href: '/collect/dashboard' })
          break;
      }
    });
  }

  return nav
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Header() {

  const [loadMe, { queryLoading, queryError, data }] = useLazyQuery(ME_QUERY )

  useEffect(() => {
    loadMe()
  }, []);


  if (queryLoading) {
    console.log(queryLoading)
  }

  if (queryError != null) {
    console.log(queryError)
  }

  if (data != null) {
    if (navigation.length == 0) {
      navigation = getNavigation()
    }
  }

  let profilePicture = data !== undefined && data.me.ProfilePicture != null ? (process.env.NODE_ENV === "development" ? "http://localhost:1337" : "") + data.me.ProfilePicture.url : ""
  let username = data !== undefined && data.me.username != null ? data.me.username : ""
  let userEmail = data !== undefined && data.me.email != null ? data.me.email : ""

  return (
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
          <Popover className="flex justify-between h-16">
            {({ open }) => (
                <>
                  <div className="flex px-2 lg:px-0">
                    <div className="flex-shrink-0 flex items-center">
                      <a href="#">
                        <img
                            className="h-8 w-auto"
                            src={logo}
                            alt="ClickAnd"
                        />
                      </a>
                    </div>
                    <nav aria-label="Global" className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4">
                      {navigation.map((item) => (
                          <Link key={item.name} to={item.href} className="px-3 py-2 text-gray-900 text-sm font-medium">
                            {item.name}
                          </Link>
                      ))}
                    </nav>
                  </div>
                  <div className="flex items-center lg:hidden">
                    {/* Mobile menu button */}
                    <Popover.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                      <span className="sr-only">Open main menu</span>
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                  <Transition.Root show={open} as={Fragment}>
                    <div className="lg:hidden">
                      <Transition.Child
                          as={Fragment}
                          enter="duration-150 ease-out"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="duration-150 ease-in"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                      >
                        <Popover.Overlay
                            static
                            className="z-20 fixed inset-0 bg-black bg-opacity-25"
                            aria-hidden="true"
                        />
                      </Transition.Child>

                      <Transition.Child
                          as={Fragment}
                          enter="duration-150 ease-out"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="duration-150 ease-in"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                      >
                        <Popover.Panel
                            focus
                            static
                            className="z-30 absolute top-0 right-0 max-w-none w-full p-2 transition transform origin-top"
                        >
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                            <div className="pt-3 pb-2">
                              <div className="flex items-center justify-between px-4">
                                <div>
                                  <img
                                      className="h-8 w-auto"
                                      src="https://tailwindui.com/img/logos/workflow-mark-blue-600.svg"
                                      alt="Workflow"
                                  />
                                </div>
                                <div className="-mr-2">
                                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                                    <span className="sr-only">Close menu</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                  </Popover.Button>
                                </div>
                              </div>
                              <div className="mt-3 px-2 space-y-1">
                                {navigation.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                    >
                                      {item.name}
                                    </Link>
                                ))}
                              </div>
                            </div>
                            <div className="pt-4 pb-2">
                              <div className="flex items-center px-5">
                                <div className="flex-shrink-0">
                                  <img className="h-10 w-10 rounded-full" src={profilePicture} alt="" />
                                </div>
                                <div className="ml-3">
                                  <div className="text-base font-medium text-gray-800">{username}</div>
                                  <div className="text-sm font-medium text-gray-500">{userEmail}</div>
                                </div>

                              </div>
                              <div className="mt-3 px-2 space-y-1">


                                <div
                                    onClick={() => navigate(`/profile/plan`)}
                                    className="cursor-pointer block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                >
                                  Profile
                                </div>

                                    <div
                                        onClick={() => logout(() => navigate(`/`))}
                                        className="cursor-pointer block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                    >
                                      Sign out
                                    </div>

                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition.Child>
                    </div>
                  </Transition.Root>
                  <div className="hidden lg:ml-4 lg:flex lg:items-center">
                    <div
                        type="button"
                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      {getUserTenant().Name}
                    </div>
                    {/* Profile dropdown */}
                    <Menu as="div" className="ml-4 relative flex-shrink-0">
                      {({ open }) => (
                          <>
                            <div>
                              <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                <span className="sr-only">Open user menu</span>
                                <img className="h-8 w-8 rounded-full" src={profilePicture} alt="" />
                              </Menu.Button>
                            </div>
                            <Transition
                                show={open}
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                  static
                                  className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                              >

                                <Menu.Item>
                                  {({ active }) => (
                                      <div
                                          onClick={() => navigate(`/profile/plan`)}
                                          className={classNames(
                                              active ? 'bg-gray-100' : '',
                                              'cursor-pointer block px-4 py-2 text-sm text-gray-700'
                                          )}
                                      >
                                        Profile
                                      </div>
                                  )}
                                </Menu.Item>

                                    <Menu.Item>
                                      {({ active }) => (
                                          <div
                                              onClick={() => logout(() => navigate(`/`))}
                                              className={classNames(
                                                  active ? 'bg-gray-100' : '',
                                                  'cursor-pointer block px-4 py-2 text-sm text-gray-700'
                                              )}
                                          >
                                            Sign out
                                          </div>
                                      )}
                                    </Menu.Item>

                              </Menu.Items>
                            </Transition>
                          </>
                      )}
                    </Menu>
                  </div>
                </>
            )}
          </Popover>
        </div>
      </header>
  )

}

export default Header;
